
<template>
    <div class="company">
        <div class="col-1-1 header_compagnie ">
            <div class="grid rel">
               <div class="header_txt vw38">{{$t('title')}}</div>
            </div>
                </div>
           
    <div class="grid grid-pad flex">
        <div class="col-1-2 mobile-col-1-1  fabrique" >
            <p v-html="$t('fabrique')"></p>
          <ul class="smalldot"> 
              <li>{{$t('li1')}}</li>
              <li>{{$t('li2')}}</li>
              <li>{{$t('li3')}}</li>
          </ul>
      </div>
      <div class="col-1-2 mobile-col-1-1 col-flex">
        <img style="display: block;" class="" src="/Resources/Images/usine_CMI.jpg" alt="" />
      </div>
    </div>
    
    
    <div class="col-1-1 grey bottom">
        <div class="grid grid-pad">
            <div class="col-1-1 fabrique">
    <h2>{{$t('history')}}</h2>
    <br />
    <p v-html="$t('history_text')"></p>
    
    <br /><br />
    
    <div class="col-1-2">
    <p v-html="$t('history_text2')"></p>
    </div>
    <div class="col-1-2 vid">
      <video poster="/Resources/Images/film.jpg" controls>
        <source src="/Resources/video/CMIpublicitefilm.mp4" type="video/mp4">
      </video>
      
    </div></div>
    </div>
    
    </div>        
    </div>
  </template>
  <i18n src="../../public/Resources/I18N/Screen.Company.json"></i18n>
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'company',
      data: () => ({

          }),
    }
    </script>
  